<!-- eslint-disable vue/no-v-html -->
<template>
  <b-row
    align-h="between"
    class="mb-3 py-0"
    :class="{
      'flex-row-reverse': ticketData['answer_by'] === 'operator',
    }"
  >
    <b-col
      cols="auto"
      class="mx-2 mt-1 d-none d-sm-block"
    >
      <b-avatar
        v-if="ticketData['answer_by'] === 'user'"
        size="50"
        variant="light-primary"
        badge
        :src="require('@/assets/images/avatars/0.png')"
        class="badge-minimal"
        badge-variant="success"
      />
      <b-avatar
        v-else
        size="50"
        variant="light-primary"
        badge
        :src="require('@/assets/images/avatars/0-admin.png')"
        class="badge-minimal"
        badge-variant="success"
      />
    </b-col>
    <b-col
      class="shadow px-2 py-2 rounded-lg"
    >
      <b-row
        align-v="center"
        align-h="between"
      >
        <b-col
          class="text-left"
        >
          <h4>
            {{ $t(ticketData.answer_by) }}
          </h4>
          <span>{{
            new Date(ticketData.created_at).toLocaleString($i18n.locale)
          }}</span>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          class="text-justify"
          cols="12"
          style="white-space: pre-wrap;"
          v-html="ticketData.context"
        />
      </b-row>
      <hr v-if="ticketData.file">
      <b-row
        v-if="ticketData.file"
        align-h="end"
      >
        <b-col
          cols="auto"
        >
          <b-link
            :href="`${baseUrl()}${ticketData.file.substring(1)}`"
            target="_blank"
          >
            <b-img
              width="100"
              thumbnail
              :src="`${baseUrl()}${ticketData.file.substring(1)}`"
            />
          </b-link>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BAvatar, BImg,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BImg,
    BLink,
  },
  props: ['ticketData'],

  methods: {
    baseUrl() {
      return process.env.VUE_APP_BASE_IMAGE_URL
    },
  },
}
</script>

<style scoped>
.user-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgb(225, 225, 225);
}
</style>
